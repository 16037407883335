import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Navbar.css';
import homeicon from './img/home5.svg';
import data from './img/data124.png';
import statsicon from './img/stats5.svg';
import menuicon from './img/menu5.svg';
import logouticon from './img/logout5.svg';
import fortotikes from './img/fortotikes.svg';
import upload from './img/upload.svg';
import tracking from './img/tracking.svg';
import contact from './img/contact.svg';
import logo from './img/logo-xit-white.png';
import pins from './img/pins.svg';
import { staticSlice } from '../store/staticSlice';
import { liscenceSlice } from '../Liscencing/LiscenceSlice';
import { useTranslation } from "react-i18next";

const Navbar = ({ toggleNav }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modules = useSelector((state) => state.liscence.modules);
  const modulesArray = modules.modules.split(',').map(Number);
  const [isExpanded, setIsExpanded] = useState(window.innerWidth > 768);
  const location = useLocation();

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsExpanded(false); // Collapse navbar on smaller screens
    } else {
      setIsExpanded(true); // Expand navbar on larger screens
    }
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleLogout = () => {
    const confirmed = window.confirm(t("confirmLogout"));

    if (confirmed) {
      localStorage.clear();
      dispatch(staticSlice.actions.clear());
      dispatch(liscenceSlice.actions.clear());
      navigate('/login');
      alert(t("loggedOut"));
    }
  };

  const toggleNavbar = () => {
    if (window.innerWidth >= 768) {
      setIsExpanded(!isExpanded);
      toggleNav();
    }
  };

  const handleImageClick = (event) => {
    event.stopPropagation();
  };

  const getActiveClass = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  return (
    <nav className={`navbar-menu ${isExpanded ? 'expanded' : 'collapsed'}`}>
      <div className="burger" onClick={toggleNavbar}>
        <img src={menuicon} alt="menu" />
      </div>
      <ul className="navbar__list">
        <li className={`navbar__li-box ${getActiveClass('/dashboard')}`}>
          <Link to="/dashboard">
            <img src={homeicon} alt="Home" onClick={handleImageClick} />
            {isExpanded && <span className="navbar-link">{t("home")}</span>}
          </Link>
        </li>
        {modulesArray.includes(1) && (
          <li className={`navbar__li-box ${getActiveClass('/fortotikes')}`}>
            <Link to="/fortotikes">
              <img src={fortotikes} alt="fortotikes" onClick={handleImageClick} />
              {isExpanded && <span className="navbar-link">{t("fortotikes")}</span>}
            </Link>
          </li>
        )}
        {modulesArray.includes(4) && (
          <li className={`navbar__li-box ${getActiveClass('/upload-fortotikes')}`}>
            <Link to="/upload-fortotikes">
              <img src={upload} alt="Upload fortotikes" onClick={handleImageClick} />
              {isExpanded && <span className="navbar-link">{t("uploadFortotikes")}</span>}
            </Link>
          </li>
        )}
        {modulesArray.includes(2) && (
          /*<li className={`navbar__li-box ${getActiveClass('/tracking')}`}>
            <Link to="/tracking">
              <img src={tracking} alt="Tracking" onClick={handleImageClick} />
              {isExpanded && <span className="navbar-link">{t("tracking")}</span>}
            </Link>
          </li>*/

          <li className={`navbar__li-box ${getActiveClass('/Voucher')}`}>
            <Link to="/Voucher">
              <img src={tracking} alt="Voucher Tracking" onClick={handleImageClick} />
              {isExpanded && <span className="navbar-link">{t("tracking")}</span>}
            </Link>
          </li>
        )}
        {modulesArray.includes(6) && (
          <li className={`navbar__li-box ${getActiveClass('/pins')}`}>
            <Link to="/pins">
              <img src={pins} alt="Pins" onClick={handleImageClick} />
              {isExpanded && <span className="navbar-link">{t("pins")}</span>}
            </Link>
          </li>
        )}
        {modulesArray.includes(4) && (
          <li className={`navbar__li-box ${getActiveClass('/contact')}`}>
            <Link to="/contact">
              <img src={contact} alt="Contact" onClick={handleImageClick} />
              {isExpanded && <span className="navbar-link">{t("contact")}</span>}
            </Link>
          </li>
        )}
        {modulesArray.includes(3) && (
          <li className={`navbar__li-box ${getActiveClass('/stats')}`}>
            <Link to="/stats">
              <img src={statsicon} alt="Statistics" onClick={handleImageClick} />
              {isExpanded && <span className="navbar-link">{t("statistics")}</span>}
            </Link>
          </li>
        )}
        {modulesArray.includes(7) && (
          <li className={`navbar__li-box ${getActiveClass('/upload-invoice')}`}>
            <Link to="/upload-invoice">
              <img src={upload} alt="Upload Invoice" onClick={handleImageClick} />
              {isExpanded && <span className="navbar-link">{t("uploadInvoice")}</span>}
            </Link>
          </li>
        )}
        {modulesArray.includes(8) && (
          <li className={`navbar__li-box ${getActiveClass('/CusCard')}`}>
            <Link to="/CusCard">
              <img src={data} alt="Customer Card" onClick={handleImageClick} />
              {isExpanded && <span className="navbar-link">{t("CusCard")}</span>}
            </Link>
          </li>
        )}
        {/*<li className={`navbar__li-box ${getActiveClass('/')}`} onClick={handleLogout}>
          <img src={logouticon} alt="Logout" onClick={handleImageClick} />
          {isExpanded && <span className="navbar-link">{t("logout")}</span>}
        </li>*/} {/*---------------LOGOUT BUTTON ON NAVBAR----------------*/}
      </ul>
      {isExpanded && (
        <div className="navbar-footer">
          <img src={logo} alt="Company Logo" className="company-logo" />
          <div className="company-info">
            <p>Design & developed by <a href="https://www.xit.gr" target="_blank" rel="noopener noreferrer">XIT</a></p>
            <p>Επικοινωνία: 2108318018</p>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
