import React, { useState } from "react";
import NavBar from "../Navbar/Navbar";
import Header from "../Header/Header";
import { useUser } from "../UserContext";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "../store/loadingSlice";
import axiosInstance from "../axiosConfig";
import "./Voucher.css";
import { useTranslation } from "react-i18next";

const Voucher = () => {
  const { t } = useTranslation();
  const [isNavOpen, setIsNavOpen] = useState(true);
  const [voucherNumber, setVoucherNumber] = useState("");
  const [trackingDetails, setTrackingDetails] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const { username, companyName } = useUser();
  const dispatch = useDispatch();
  const licenseData = useSelector((state) => state.liscence.modules);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleTrack = async () => {
    setErrorMessage("");
    setTrackingDetails([]);

    if (!voucherNumber.trim()) {
      setErrorMessage("Voucher number is empty.");
      return;
    }

    try {
      dispatch(showLoading());

      const thisClientID = localStorage.getItem("token");
      const parameters = {
        url: `https://${licenseData.company_url}/s1services`,
        service: "SqlData",
        clientID: thisClientID,
        appId: "1001",
        SqlName: "9120",
        voucher: voucherNumber,
      };

      const response = await axiosInstance.post("/api", parameters);

      if (response?.data?.success && Array.isArray(response.data.rows)) {
        if (response.data.rows.length > 0) {
          setTrackingDetails(response.data.rows);
        } else {
          setErrorMessage("Invalid format or voucher doesn't exist.");
        }
      } else {
        setErrorMessage("API response format is invalid.");
      }
    } catch (error) {
      console.error("Error during API call:", error);
      setErrorMessage("An error occurred while processing the request.");
    } finally {
      dispatch(hideLoading());
    }
  };

  const renderTrackingMap = () => {
    if (trackingDetails.length === 0) {
      return <p id="contentBox">Εισάγετε Voucher Παραπάνω</p>;
    }

    const progressList = trackingDetails.map((row, index) => {
      const status = row.voucherstatus;
      const title = row.statusname;
      const insdate = row.insdate;
      const comment = row.comments;
      const formattedDate = new Date(insdate).toLocaleDateString("en-US", {
        weekday: "short",
        month: "short",
        day: "numeric",
      });

      return (
        <li
          key={index}
          className={`progress_item1 ${
            status === "Ολοκληρώθηκε" || index < trackingDetails.length - 1
              ? "completed"
              : ""
          }`}
        >
          <p className="progress_title1">{title}</p>
          <p className="progress_date1">{formattedDate}</p>
          {comment && <p className="progress_comment1">{comment}</p>}
        </li>
      );
    });

    return <ul className="progress1">{progressList}</ul>;
  };

  return (
    <div className={`container-tracking ${isNavOpen ? 'nav-open' : 'nav-closed'}`}>
      <NavBar toggleNav={toggleNav} />
      <div className="dashboard-content">
        <Header username={username} companyName={companyName} />
        <div className="main-content">
          <div className="container-tracking1">
            <h1>Tracking</h1>
            <div className="search-container1">
              <input
                type="text"
                id="voucher_input1"
                placeholder="Αναζήτηση..."
                value={voucherNumber}
                onChange={(e) => setVoucherNumber(e.target.value)}
                className="tracking-input-field"
              />
             <button
                onClick={handleTrack}
                id="voucher_submit1"
                className="tracking-button1"
              >
                {t("search")}
              </button>
            </div>
            {errorMessage && (
              <p id="message" style={{ color: "red" }}>
                {errorMessage}
              </p>
            )}
            <div className="progressContainer1" id="defaultProgressContainer1">
              {renderTrackingMap()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Voucher;
