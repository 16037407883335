import React, { useRef, useState } from 'react';
import axiosInstance from '../axiosConfig';
import './UploadInvoice.css';
import NavBar from "../Navbar/Navbar";
import Header from '../Header/Header';
import { useUser } from '../UserContext';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from '../store/loadingSlice';

function UploadInvoice() {
  const [isNavOpen, setIsNavOpen] = useState(true);
  const { username, companyName } = useUser();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [data, setData] = useState(null);
  const licenseData = useSelector((state) => state.liscence.modules);

  const extractaApiUrl = process.env.REACT_APP_EXTRACTA_API_URL;

  const extractionId = "-OBq3_hWHPXJ1Vuo5jNu";

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const fetchExtractaBatchResults = async (payload) => {
    const maxRetries = 5; // Number of attempts
    const delay = 5000; // 5 seconds between attempts
    let attempt = 0;

    while (attempt < maxRetries) {
      try {
        // Fetch batch results
        const response = await axiosInstance.post("/extracta", payload);

        const { status, data } = response;
        if (status === 200 && data) {
          if (status === 200 && data.status === "waiting") {
            console.log(`Attempt ${attempt + 1}: Processing still waiting...`);
            await new Promise((resolve) => setTimeout(resolve, delay)); // Wait before retrying
          } else {
            return data; // Return data on completed
          }
        } else {
          console.error("Unexpected status:", data);
          break; // Exit on unexpected status
        }
      } catch (error) {
        console.error("Error fetching batch results:", error.message);
        break; // Exit on error
      }

      attempt++;
    }

    console.error("Exceeded maximum retries. Batch processing not completed.");
    return null; // Return null
  }

  // Function to fetch TRDR using VAT number from the invoice data
  const fetchSupplierTRDR = async (vat) => {
    // Fetch TRDR if VAT is available
    if (vat) {
      const parameters = {
        url: `https://${licenseData.company_url}/s1services`,
        service: "getBrowserInfo",
        clientID: localStorage.getItem("token"),
        appId: "1001",
        OBJECT: "SUPPLIER",
        LIST: "",
        VERSION: 2,
        LIMIT: 1,
        FILTERS: `SUPPLIER.AFM=${vat}`
      };

      try {
        const response = await axiosInstance.post("/api", parameters);
        if (response.data.success && response.data.rows && response.data.rows.length > 0) {
          const value = response.data.rows[0][0];
          const trdrId = value.match(/(\d+)$/) ? value.match(/(\d+)$/)[0] : null; // Extract the TRDR
          return trdrId;
        } else {
          console.error('Supplier TRDR not found.', response.data);
          return null;
        }
      } catch (error) {
        console.error('Error fetching TRDR:', error);
        return null;
      }
    } else {
      console.log("VAT number not available in invoice data.");
      return null;
    }
  };

  const handleFileUpload = async (apiType) => {
    const file = fileInputRef.current?.files[0];
    if (!file) {
      alert("Please select a file to upload.");
      return;
    }

    dispatch(showLoading());

    try {
      let invoiceData;
      if (apiType === "veryfi") {
        invoiceData = await handleVeryfiUpload(file);
      } else if (apiType === "extracta") {
        invoiceData = await handleExtractaUpload(file);
      }

      if (!invoiceData) return;

      // Fetch TRDR using VAT Number
      const trdr = await fetchSupplierTRDR(invoiceData.merchantTaxId);
      if (!trdr) {
        alert("Supplier TRDR not found.");
        return;
      }

      // Prepare transformed data
      const transformedData = prepareTransformedData(invoiceData, trdr);
      setData(transformedData);
      alert("Invoice data processed successfully!");

    } catch (error) {
      console.error("Error processing invoice:", error);
      alert("Error processing invoice!");
    } finally {
      dispatch(hideLoading());
    }
  };

  const handleVeryfiUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const uploadResponse = await axiosInstance.post("/veryfi", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });

      if (uploadResponse.data && uploadResponse.data.status === "fail") {
        alert(uploadResponse.data.error);
        return null;
      }

      const invoiceData = mapVeryfiResponse(uploadResponse.data);
      console.log("Veryfi Invoice Data:", invoiceData);
      return invoiceData;
    } catch (error) {
      console.error("Veryfi API error:", error);
      alert(`Veryfi API Error: ${error.message}`);
      return null;
    }
  };

  const handleExtractaUpload = async (file) => {
    const formData = new FormData();
    formData.append("files", file);
    formData.append("extractionId", extractionId);
    formData.append("url", `${extractaApiUrl}/uploadFiles`);

    try {
      const uploadResponse = await axiosInstance.post("/extracta", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        } // Content-Type is manditory
      });

      if (uploadResponse.data && uploadResponse.data.status === "error") {
        alert(uploadResponse.data.message);
        return null;
      }

      if (
        !uploadResponse.data ||
        !uploadResponse.data.batchId ||
        !uploadResponse.data.files ||
        uploadResponse.data.files.length === 0
      ) {
        alert("Failed to upload file to Extracta");
        return null;
      }

      const batchId = uploadResponse.data.batchId;
      const fileId = uploadResponse.data.files[0].fileId;

      const resultsPayload = {
        extractionId,
        batchId,
        fileId,
        url: `${extractaApiUrl}/getBatchResults`
      };

      const resultsResponse = await fetchExtractaBatchResults(
        resultsPayload
      );

      const { files } = resultsResponse;
      if (!files || files.length === 0) {
        alert("No files processed in batch.");
        return null;
      }

      const extractedFile = files[0];
      if (extractedFile.status !== "processed") {
        alert(`File not processed: ${extractedFile.status}`);
        return null;
      }

      const { result } = extractedFile;
      const invoiceData = mapExtractaResponse(result);

      console.log("Extracta Invoice Data:", invoiceData);
      return invoiceData;
    } catch (error) {
      console.error("Extracta API error:", error.message);
      alert(`Extracta API Error: ${error.message}`);
      return null;
    }
  };

  const mapExtractaResponse = (invoiceData) => {
    return {
      clientAddress: invoiceData.client?.client_address || "Unknown",
      clientTaxId: invoiceData.client?.client_tax_id || null,
      invoiceNumber: invoiceData.invoice_id || "Unknown",
      invoiceDate: invoiceData.invoice_date || "Unknown",
      invoicecomments: invoiceData.invoice_comments || null,
      invoiceremarks: invoiceData.invoice_end_user || null,
      invoiceTotal: parseFloat(invoiceData.invoice_grand_total || "0.00").toString(),
      items:
        invoiceData.items?.map((item) => ({
          sku: item.sku || item.reference || "9999",
          comment: item.name,
          quantity: parseInt(item.quantity || 1),
          unitPrice: parseFloat(item.unit_price || 0),
          tax: item.tax,
          discount1:parseFloat(item.discount_1_value || 0),
          discount2:parseFloat(item.discount_2_value || 0),
          discount3:parseFloat(item.discount_3_value || 0),
          totalPrice: parseFloat(item.total_price || 0),
        })) || [],
      merchantTaxId: invoiceData.merchant?.merchant_tax_id || null,
      merchantaddress: invoiceData.merchant?.merchant_address || null,
    };
  };

  const mapVeryfiResponse = (invoiceData) => {
    return {
      invoiceNumber: invoiceData.invoice_number || "Unknown",
      clientTaxId: invoiceData.vendor?.vat_number || null,
      invoiceDate: invoiceData.date || "Unknown",
      invoiceTotal: invoiceData.total || "0.00",
      clientAddress: invoiceData.ship_to?.address || "GIANNARI 4",
      lineItems: (invoiceData.line_items || []).length.toString(),
      items: (invoiceData.line_items || []).map((item) => ({
        sku: item.sku || item.reference || "9999",
        comment: item.text || item.description || "",
        quantity: (item.quantity || 1).toString(),
        discount: item.discount || "0.00",
        unitPrice: (item.price || 0).toFixed(2),
        totalPrice: item.total // || ((item.quantity || 1) * (item.price || 0)).toFixed(2)
      })),
    };
  };

  const prepareTransformedData = (invoiceData, trdr) => ({
    url: `https://${licenseData.company_url}/s1services`,
    service: "setData",
    clientID: localStorage.getItem("token"),
    appId: 1001,
    OBJECT: "PURDOC",
    KEY: "",
    data: {
      PURDOC: [
        {
          SERIES: 2062,
          CMPFINCODE: invoiceData.invoiceNumber,
          TRNDATE: invoiceData.invoiceDate,
          TRDR: trdr,
          PAYMENT: 1003,
          SHIPMENT: "1000",
          COMMENTS: invoiceData.invoicecomments,
          REMARKS: invoiceData.invoiceremarks,
          SUMAMNT: invoiceData.invoiceTotal,
          line_items: (invoiceData.items || []).length.toString(),
        },
      ],
      MTRDOC: [
        {
          SHIPPINGADDR: invoiceData.merchantaddress,
        },
      ],
      ITELINES: invoiceData.items?.map((item, index) => ({
        LINENUM: index,
        SRCHCODE: item.sku,
        QTY1: item.quantity,
        PRICE: item.unitPrice,
        DISC1VAL: item.discount1,
        DISC2VAL: item.discount2,
        DISC3VAL: item.discount3,
        COMMENTS: item.comment,
        COMMENTS2: item.sku,
        LINEVAL: item.totalPrice,
      })) || [],
    },
  });

  const handleDataUpload = async () => {
    if (!data) {
      alert('No data to upload!');
      return;
    }

    try {
      dispatch(showLoading());
      const response = await axiosInstance.post("/api", data);
      if (response.data.success) {
        alert('Invoice uploaded successfully!');
      } else {
        alert(`Error uploading invoice: ${response.data.error}`);
      }
    } catch (error) {
      console.error('Error uploading invoice:', error);
      alert('Error uploading invoice!');
    } finally {
      dispatch(hideLoading());
    }
  };

  return (
    <div className={`container-upload ${isNavOpen ? 'nav-open' : 'nav-closed'}`}>
      <NavBar toggleNav={toggleNav} />
      <div className="dashboard-content">
        <Header username={username} companyName={companyName} />
        <div className="main-content">
          <h1>Upload PDF Invoice File</h1>
          <input
            type="file"
            className='form-control'
            accept=".pdf"
            onClick={(e) => {
              e.target.value = null;
            }}
            ref={fileInputRef}
          />
          <button onClick={() => handleFileUpload("veryfi")} className="btn btn-primary m-3">
            Upload to Veryfi
          </button>
          <button onClick={() => handleFileUpload("extracta")} className="btn btn-secondary m-3">
            Upload to Extracta
          </button>
          {data && (
            <button onClick={handleDataUpload} className="btn btn-success m-3">
              Submit Invoice
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default UploadInvoice;
